import React, { useState } from 'react';
import '../../styles/medical/medical-surgery.scss';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/informationsite/vaccination240401.scss';

const Medicalhourschange = () => {
    
    return (
        <div className="informationsites">
                <ImageWithTitle title="診療時間変更のお知らせ"/>

                <div className="main">
                    <section className="01">
                        <div>
                            <h3>2025年1月4日より午後の診療時間が下記の通り変更となります。</h3>
                            <p>
                                患者様にはご迷惑とご不便をお掛けしますが、<br />
                                何卒ご理解、ご協力のほどよろしくお願い申し上げます。
                            </p>
                        </div>
                    </section>

                    <section className="02">
                        <div>
                            <h3 >【変更後】</h3>
                            
                            <div className="box">
                                <p>月・火・水・木・金</p>
                                <h1>
                                    PM 15:00〜17:00
                                </h1>
                            </div>

                            <p>※午前中の診療時間は変更にはなりません。</p>
                        </div>
                    </section>

                </div>

        </div>


    )
}

export default Medicalhourschange;