import React, { useState } from 'react';
import '../../styles/medical/medical-surgery.scss';
import ImageWithTitle from '../components/imagewithtitle';
import '../../styles/informationsite/vaccination240401.scss';

const Newyearholiday2024 = () => {
    
    return (
        <div className="informationsites">
                <ImageWithTitle title="年末年始のお知らせ"/>

                <div className="main">
                    <section className="01">
                        <p>
                            年末年始は<span className="bold">12月29日（日曜日）から1月3日（金曜日）</span>まで休診となります。<br />
                            ご迷惑をおかけしますがよろしくお願いいたします。<br />
                            来年は1月4日（土曜日）から通常診療となります。<br />
                            来年もよろしくお願いいたします。<br />
                        </p>
                    </section>

                </div>

        </div>


    )
}

export default Newyearholiday2024;