/**メインページ */
import React from 'react';
import { Link } from 'react-router-dom';
import Button from '../components/button.js'; 
import MedicalSlider from '../components/medicalslider.js';
import '../../styles/medical/medical-top.scss';
import triangleLine from '../icons/triangleline.svg';
import triangleMatrix from '../icons/trianglematrix.svg';
import ButtonSubject from '../components/button-subject.js';
import doctor from '../img/doctor.JPG';
import Facility1 from '../img/Facility-1.JPG';
import Facility2 from '../img/Facility-2.JPG';
import Facility3 from '../img/Facility-3.JPG';
import Facility4 from '../img/Facility-4.JPG';
import Facility5 from '../img/Facility-5.JPG';
import Facility6 from '../img/Facility-6.JPG';
import Access from '../components/access.js';
import ButtonBox from '../components/buttonbox';
import MedicalInjection from '../components/medicalinjection.js';

function MedicalTop() {
  return (
    <div className="medical-top">

      {/* 1. キービジュアルセクション */}
      <section className="key-visual">
        <div className="for-pc">
          <div className="key-visual__flex">
                  <div className="key-visual__links">
                      <Link to="/medical-surgery">科目別で探す</Link>
                      <Link to="/medical-introduction">施設・設備紹介</Link>
                  </div>
                  <div className="key-visual__visual">
                        <MedicalSlider /> {/* スライダーコンポーネントを使用 */}
                  </div>
          </div>
        </div>
        <div className="for-sp">
          <div className="key-visual__visual">
              <MedicalSlider /> {/* スライダーコンポーネントを使用 */}
          </div>  
        </div>
      </section>

      <div className="for-pc">
      {/* 4. お知らせセクション */}
      <section className="news">
          <div className="title">
              <img className="title__picture" src={triangleLine} alt="" />
              <h2 className="title__text">お知らせ</h2>
          </div>
          <ul className="news__contents">
            <li><span>2024-12-06</span> <span><Link className="underbar" to="/information-03">診療時間変更のお知らせ</Link></span></li>
            <li><span>2024-12-06</span> <span><Link className="underbar" to="/information-02">年末年始の休診日のお知らせ</Link></span></li>
            <li><span>2024-08-05</span> <span>当院の夏季休診は8月12日月曜日から15日木曜日になります</span></li>
            <li><span>2024-04-05</span> <span><Link className="underbar" to="/information-01">『帯状疱疹予防接種の公費助成』</Link>について更新しました。</span></li>
            <li><span>2024-01-10</span> <span>現在ホームページの一部が改修中となっており、多大なるご迷惑をおかけしております。</span></li>
            <li><span>2023-10-25</span> <span>ホームページが長期間非表示になっていたことをお詫びいたします</span></li>
          </ul>
      </section>

      {/* 3. 科目別で探すセクション */}
      <section className="medical-top__departments">
          <h2 className="title__text">科目別で探す</h2>
          <ButtonBox />
          <div className="medical-top__img">
            <img src={triangleMatrix} className="medical-top__img--topleft" />
            <img src={triangleMatrix} className="medical-top__img--bottomright" />
          </div>
      </section>

      {/* 5. 施設・設備紹介 */}
      <section className="medical-top__facilities">
          <h2 className="title__text">
            施設・設備紹介
          </h2>
          <div className="medical-top__facility-grid">
            <img className="medical-top__facility-grid--1" src={Facility1}></img>
            <img className="medical-top__facility-grid--2" src={Facility2}></img>
            <img className="medical-top__facility-grid--3" src={Facility3}></img>
            <img className="medical-top__facility-grid--4" src={Facility4}></img>
            <img className="medical-top__facility-grid--5" src={Facility5}></img>
            <img className="medical-top__facility-grid--6" src={Facility6}></img>
            <div className="medical-top__facility-vector"></div>
          </div>
          <Button to="/medical-surgery">詳しく見る</Button>
      </section>

      {/* 6. アクセス */}
      <Access />
      </div>

      <div className="for-sp">
      {/* 4. お知らせセクション */}
      <section className="news">
          <div className="title">
              <img className="title__picture" src={triangleLine} alt="" />
              <h2 className="title__text">お知らせ</h2>
          </div>
          <ul className="news__contents">
              <li><span>2024-12-06</span> <span><Link className="underbar" to="/information-03">診療時間変更のお知らせ</Link></span></li>
              <li><span>2024-12-06</span> <span><Link className="underbar" to="/information-02">年末年始の休診日のお知らせ</Link></span></li>
              <li><span>2024-08-05</span> <span>当院の夏季休診は8月12日月曜日から15日木曜日になります</span></li>
              <li><span>2024-04-05</span> <span><Link className="underbar" to="/information-01">『帯状疱疹予防接種の公費助成』</Link>について更新しました。</span></li>
              <li><span>2024-01-10</span> <span>現在ホームページの一部が改修中となっており、多大なるご迷惑をおかけしております。</span></li>
              <li><span>2023-10-25</span> <span>ホームページが長期間非表示になっていたことをお詫びいたします</span></li>
          </ul>
      </section>

      {/* 3. 科目別で探すセクション */}
      <section className="medical-top__departments">
          <h2 className="title__text">科目別で探す</h2>
          <ButtonBox />
      </section>

      {/* 6. アクセス */}
      <Access />
      </div>

    </div>
  );
}

export default MedicalTop;
